import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  AppBar,
  Toolbar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function LoginPage({ login }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const success = login({ email, password });
    if (success) {
      navigate('/home');
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#0D98B8', // Set background color of Box to blue
        height: '100vh', // Full height of the viewport
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Container 
        maxWidth="xs" 
        sx={{ 
          backgroundColor: '#313943', 
          height: 'auto', // Adjust height to fit content
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center',
          color: 'white', // Set text color to white
          padding: '2rem', // Optional padding
        }}
      >
        <AppBar position="static" sx={{ backgroundColor: '#313943' }}>
          <Toolbar>
            <Typography variant="h6">Analytics, Attribution, and Activation</Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
          Log in to RioStack
          </Typography>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{ style: { color: 'white' } }} // Change label color to white
              InputProps={{ style: { color: 'white' } }} // Change input text color to white
              sx={{ backgroundColor: '#444F56' }} // Background for input field
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{ style: { color: 'white' } }} // Change label color to white
              InputProps={{ style: { color: 'white' } }} // Change input text color to white
              sx={{ backgroundColor: '#444F56' }} // Background for input field
            />
            <Button 
              variant="contained" 
              color="primary" 
              type="submit" 
              fullWidth 
              sx={{ backgroundColor: '#2FDB9D', '&:hover': { backgroundColor: '#27B68A' } }} // Set button color and hover effect
            >
              Continue
            </Button>
          </form>
        </Box>
      </Container>
    </Box>
  );
}

export default LoginPage;