import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './LoginPage';
import HomePage from './HomePage'; // Create this component

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = (credentials) => {
    const { email, password } = credentials;
    console.log('Attempting to log in with:', email, password);
    if (email === 'brandon@riostack.com' && password === 'password') {
      console.log('Login successful!');
      setIsAuthenticated(true);
      return true; // Return true on success
    } else {
      console.log('Login failed.');
      return false; // Return false on failure
    }
  };
  
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<LoginPage login={login} />}
        />
        <Route
          path="/home"
          element={isAuthenticated ? <HomePage /> : <Navigate to="/login" />}
        />
        <Route
          path="*"
          element={<Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
}

export default App;